@charset "UTF-8";
/* CSS Document */
/*******************
    General Styles
********************/

/*******************
      Nav Styles
********************/
body {
  &.menu-open {
    .nav-wrapper {
      nav.main-navigation {
        .container {
          span {
            &.hamburger {
              &:before {
                transform: rotate(45deg) translateY(-50%);
                top: 50%;
              }

              &:after {
                transform: rotate(-45deg) translateY(-50%);
                top: 50%;
              }

              > span {
                height: 0;
              }
            }
          }
        }
        ul.menu-links {
          transform: translateY(0%);
        }
      }
    }
  }
}

.nav-wrapper {
  position: fixed;
  z-index: 999999;
  width: 100%;
  nav {
    &.cta {
      width: 100%;
      height: 45px;
      line-height: 45px;
      background-color: @white;
      position: relative;
      z-index: 99999;
      span {
        display: block;
        text-align: center;
        .defaultH4();
        color: @secondary-color;
        letter-spacing: 0;
        margin-bottom: 0;

        a {
          text-decoration: none;
          transition: all .25s ease-in-out;
          color: @secondary-color;
          &:hover {
            opacity: .5;
          }
        }
      }
    }
    &.main-navigation {
      display: flex;
      background-color: @main-color;
      position: relative;
      .container {
        height: 60px;
        width: 100%;
        background-color: @main-color;
        z-index: 99999;
        a.menu-trigger {
          width: 44px;
          margin-left: 1rem;
          .hamburger {
            margin: 0 auto;
            position: relative;
            width: 30px;
            height: 20px;
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: @white;
              transition-duration: .1s,.1s;
              transition-delay: .1s,0s;
              transition-property: top,transform;
            }
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 2px;
              background: @white;
              transition-duration: .1s,.1s;
              transition-delay: .1s,0s;
              transition-property: bottom,transform;
            }
            > span {
              position: absolute;
              top: 50%;
              left: 0;
              width: 100%;
              height: 2px;
              background: @white;
              transform: translateY(-50%);
              //transition: .1s .1s;
              //transition-delay: 0s,.1s;
              transition: all .25s ease-in-out;
            }
          }
        }
        ul.social-icons {
          .clean-list();
          display: inline-flex;
          flex-direction: row;
          margin-right: 1rem;
          position: relative;
          z-index: 99999;
          background-color: @main-color;
          margin-left: auto;
          li {
            margin: 0 .25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
              font-size: 1.25rem;
              color: @white;
              transition: all .25s ease-in-out;
              &:hover {
                color: @secondary-color;
              }
            }
          }
        }
      }
      ul.menu-links {
        .clean-list();
        display: inline-flex;
        margin-right: auto;

        position: absolute;
        top: 60px;
        left: 0;
        background-color: @main-color;
        flex-direction: column;
        width: 100%;
        transition: all .25s ease-in-out;
        transform: translateY(-200%);


        li {
          line-height: 300%;
          //height: 60px;
          //margin: 0 .5rem;
          position: relative;

          &.current-menu-item {
            &.anchor-link {
              a {
                color: @white;
              }
            }
            a {
              color: @secondary-color;
            }
          }
          a {
            color: @white;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            height: 100%;
            justify-content: center;
            text-transform: uppercase;
            font-size: .85rem;
            transition: all .25s ease-in-out;

            margin: 0 1.5rem;

            &:hover {
              color: @secondary-color;
            }
          }
          > ul.submenu-links {
            display: block;
            width: 100%;
            .clean-list();
            background-color: @secondary-color;
            transition: all .25s ease;
            li {
              padding: 0 1rem;
              line-height: 300%;
              a {
                transition: all .25s ease-in-out;
                margin: 0 1rem;
                color: @white;
                &:hover {
                  opacity: .5;
                }
              }
            }
          }
        }
      }
    }
  }
}
/*******************/

/*******************
    Header Styles
********************/
header {}

/*******************/

/*******************
     Main Styles
********************/
body.home {
  main.content {
    margin-top: 0;
  }
}

main.content {
  margin-top: 104px;
}
/*******************/

/*******************
    Footer Styles
********************/
footer {
  p.footer__copyright {
    margin-bottom: 0;
  }
}
/*******************/