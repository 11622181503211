@charset "UTF-8";
/* CSS Document */
/************************************
   Normalise, Variables and Mixins
************************************/
/* CSS Document */
/*********************
      Reset CSS
**********************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* CSS Document */
/*******************
        Vars
********************/
/*******************
       Colours
********************/
/* CSS Document */
/*******************
       Mixins
********************/
/******************
    Fixes/Resets
*******************/
.clean-list-style,
.slick-dots,
.block--contact-section ul.contact-information,
.nav-wrapper nav.main-navigation .container ul.social-icons,
.nav-wrapper nav.main-navigation ul.menu-links,
.nav-wrapper nav.main-navigation ul.menu-links li > ul.submenu-links {
  list-style: none;
  margin: 0;
  padding: 0;
}
.clean-list-style li,
.slick-dots li,
.block--contact-section ul.contact-information li,
.nav-wrapper nav.main-navigation .container ul.social-icons li,
.nav-wrapper nav.main-navigation ul.menu-links li,
.nav-wrapper nav.main-navigation ul.menu-links li > ul.submenu-links li {
  margin: 0;
  padding: 0;
  background: none;
  line-height: 120%;
}
.smooth-style,
html,
body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.p,
.block--page-header__caption,
.block-accordion__header,
.nav-wrapper nav.cta span,
.wpcf7 .wpcf7-form textarea,
.wpcf7 .wpcf7-form .wpcf7-submit {
  -webkit-font-smoothing: antialiased;
}
/***************
     Forms
****************/
/****************
    Fonts
*****************/
/******************
      Button
*****************/
/******************
      Loops
*****************/
/***********************************/
/************************************
                Fonts
************************************/
/* CSS Document */
/*******************
    Font Styles
********************/
html,
body {
  font-family: sans-serif;
}
/******************/
/*******************
  Text Align Styles
********************/
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
/*******************/
/****************
    Text Styles
*****************/
h1,
.h1 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 2.5rem;
  line-height: 150%;
  margin: 0 0 15px;
  font-weight: 900;
}
h2,
.h2 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 2.25rem;
  line-height: 125%;
  margin: 0 0 15px;
}
h3,
.h3 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.25rem;
  line-height: 125%;
  margin: 0 0 15px;
}
h4,
.h4 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 5px;
}
h5,
.h5 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 5px;
}
h6,
.h6 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 5px;
}
p,
.p {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.7rem;
  margin: 0 0 25px 0;
  text-align: justify;
}
p a:not(.btn),
.p a:not(.btn) {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
  transition: all 0.25s ease-in-out;
}
p a:not(.btn):hover,
.p a:not(.btn):hover {
  opacity: 0.5;
}
p.h1,
.p.h1,
p .h2,
.p .h2,
p .h3,
.p .h3,
p .h4,
.p .h4,
p .h5,
.p .h5,
p .h6,
.p .h6 {
  text-align: unset;
}
ul,
.ul {
  margin-left: 2.5rem;
  margin-bottom: 1rem;
  list-style: disc;
}
ul li,
.ul li {
  margin-bottom: 0.75rem;
  display: list-item;
  line-height: 1.5rem;
}
/****************/
/***********************************/
/************************************
            Common Styles
************************************/
/* CSS Document */
html {
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
#wpadminbar {
  z-index: 999999999 !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.wrapper {
  width: 80%;
  margin: 0 auto;
}
.wrapper--small {
  width: 80%;
}
::selection {
  background-color: #0f284a;
  color: #75D1E1;
}
img.aligncenter {
  display: block;
  margin: 0 auto;
}
img.size-full {
  padding: 0.5rem;
}
/*******************/
.fancy-divider {
  height: 3px;
  margin: 1rem 0 2rem 0;
}
.fancy-divider--small {
  width: 40px;
}
.fancy-divider--large {
  width: 70px;
}
.fancy-divider--main-color {
  background-color: #0f284a;
}
.fancy-divider--secondary-color {
  background-color: #75D1E1;
}
.fancy-divider--left {
  margin-left: 0;
  margin-right: auto;
}
.fancy-divider--center {
  margin-left: auto;
  margin-right: auto;
}
/*******************
   Section Styles
********************/
.section-white {
  background-color: #fff;
}
.section-black {
  background-color: #000;
}
.section-main-color {
  background-color: #0f284a;
  color: #fff;
}
.section-secondary-color {
  background-color: #75D1E1;
}
/*******************/
/*******************
   Colour Styles
********************/
.white {
  color: #fff;
}
.main-color {
  color: #0f284a;
}
.secondary-color {
  color: #75D1E1;
}
/*******************/
/*******************
     Btn Styles
********************/
.btn {
  padding: 0.75em 1.5em;
  margin: 1.5rem 0;
  border-radius: 1.5em;
  text-decoration: none;
  display: inline-flex;
  line-height: 1em;
  text-align: center;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
}
.btn--primary {
  background-color: #0f284a;
  color: #fff;
  border: 2px solid #0f284a;
  transition: 0.2s ease-in-out;
}
.btn--primary:hover {
  border: 2px solid #0f284a;
  background-color: rgba(0, 0, 0, 0);
  color: #0f284a;
}
.btn--primary-outline {
  background-color: rgba(0, 0, 0, 0);
  color: #0f284a;
  border: 2px solid #0f284a;
  transition: 0.2s ease-in-out;
}
.btn--primary-outline:hover {
  background-color: #0f284a;
  color: #fff;
}
.btn--secondary {
  background-color: #75D1E1;
  color: #fff;
  border: 2px solid #75D1E1;
  transition: 0.2s ease-in-out;
}
.btn--secondary:hover {
  border: 2px solid #75D1E1;
  background-color: rgba(0, 0, 0, 0);
  color: #75D1E1;
}
.btn--secondary-outline {
  background-color: rgba(0, 0, 0, 0);
  color: #75D1E1;
  border: 2px solid #75D1E1;
  transition: 0.2s ease-in-out;
}
.btn--secondary-outline:hover {
  background-color: #75D1E1;
  color: #fff;
}
.btn--white {
  background-color: #fff;
  color: #0f284a;
  border: 2px solid #fff;
  transition: 0.2s ease-in-out;
}
.btn--white:hover {
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}
.btn--small-btn {
  display: inline-block;
  width: 200px;
}
.btn--center {
  margin: 0 auto;
}
/*******************/
/*******************
    Block Styles
********************/
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
/*******************/
/*******************
  Flex Box Styles
********************/
.flex {
  display: flex;
}
.flex.flex--row {
  flex-direction: row;
}
.flex.flex--column {
  flex-direction: column;
}
.flex.flex--wrap {
  flex-wrap: wrap;
}
.flex.flex--justify-center {
  justify-content: center;
}
.flex.flex--justify-space-between {
  justify-content: space-between;
}
.flex.flex--justify-start {
  justify-content: flex-start;
}
.flex.flex--justify-end {
  justify-content: flex-end;
}
.flex.flex--align-stretch {
  align-items: stretch;
}
.flex.flex--align-self {
  align-self: center;
}
.flex.flex--align-center {
  align-items: center;
}
.flex.flex--align-start {
  align-items: flex-start;
}
.flex.flex--align-end {
  align-items: flex-end;
}
/******************/
/*******************
       Layout
********************/
.row-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.row {
  display: flex;
  flex-direction: column;
}
.row--reverse {
  flex-direction: column-reverse;
}
.row .col {
  display: inline-flex;
  flex-direction: column;
}
.row .col--gutter {
  padding: 1.5rem 0;
}
.row .col--gutter-large {
  padding: 3rem 0;
}
.row .col--1-1 {
  width: 100%;
}
.row .col--1-2 {
  width: 100%;
}
.row .col--1-3 {
  width: 100%;
}
.row .col--2-3 {
  width: 100%;
}
.row .col--1-4 {
  width: 100%;
}
.row .col--1-6 {
  width: 100%;
}
.padded {
  padding: 3rem 0;
}
.padded--large {
  padding: 6rem 0;
}
.padded--top {
  padding-top: 3rem;
}
.padded--top-large {
  padding-top: 6rem;
}
.padded--bottom {
  padding-bottom: 3rem;
}
.padded--bottom-large {
  padding-bottom: 6rem;
}
.padded--no-top {
  padding-top: 0;
}
.padded--no-bottom {
  padding-bottom: 0;
}
/*******************/
/*******************
    Slick Carousel
********************/
/* Defaults */
.slider-container {
  position: relative;
}
.slide {
  outline: 0;
}
.slick-dots {
  display: flex;
  justify-content: center;
  margin: 1em 0;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -10%);
}
.slick-dots li {
  margin: 0 0.5em;
}
.slick-dots li button {
  font-size: 0;
  height: 10px;
  width: 10px;
  padding: 0;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.35);
  border: 0;
  outline: none;
}
.slick-dots li button:hover {
  opacity: 0.8;
  cursor: pointer;
}
.slick-dots li.slick-active button {
  background-color: #fff;
  border: 0;
}
.slider-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  display: none;
  font-size: 3rem;
  color: #fff;
}
.slider-controls .slick-prev {
  left: 0;
  position: absolute;
  transition: all 0.25s ease-in-out;
}
.slider-controls .slick-prev:hover {
  opacity: 0.5;
  cursor: pointer;
}
.slider-controls .slick-next {
  right: 0;
  position: absolute;
  transition: all 0.25s ease-in-out;
}
.slider-controls .slick-next:hover {
  opacity: 0.5;
  cursor: pointer;
}
.testimonial-slider .slick-dots {
  bottom: auto;
  transform: translate(-50%, 0);
}
.testimonial-slider .slick-dots li button {
  background-color: rgba(15, 40, 74, 0.4);
}
.testimonial-slider .slick-dots li.slick-active button {
  background-color: #0f284a;
}
.testimonial-slider .slider-controls {
  color: #75D1E1;
}
.testimonial-slider .testimonial p {
  width: 100%;
  margin: 0 auto;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.testimonial-slider .testimonial__business-name {
  color: #75D1E1;
}
.logo-slider .slick-dots {
  bottom: auto;
  transform: translate(-50%, 0);
}
.logo-slider .slick-dots li button {
  background-color: rgba(15, 40, 74, 0.4);
}
.logo-slider .slick-dots li.slick-active button {
  background-color: #0f284a;
}
.logo-slider .slider-controls {
  color: #75D1E1;
}
.logo-slider .slide a:focus,
.logo-slider .slide img:focus {
  outline: 0;
}
.logo-slider .slide a img,
.logo-slider .slide img {
  margin: 0 auto;
}
.logo-slider .slide a img:focus,
.logo-slider .slide img:focus {
  outline: 0;
}
/*******************/
/*******************
 Animate On Scroll
********************/
[data-aos] {
  visibility: hidden;
}
[data-aos].aos-animate {
  visibility: visible;
}
/******************/
/*******************
Invisible reCaptcha
********************/
.inv-recaptcha-holder {
  padding: 25px 0;
}
/******************/
/*******************
  Parallax Styles
********************/
.parallax-window {
  background: rgba(0, 0, 0, 0);
  min-height: 100%;
  transition: all 0.3s ease;
}
.parallax-window:after {
  content: '';
  padding-bottom: 50%;
}
/******************/
/*******************
  Fancy Box Styles
********************/
.compensate-for-scrollbar {
  margin-right: 0 !important;
}
.fancybox-bg {
  background-color: #0f284a;
}
.fancybox-infobar {
  color: #fff;
  mix-blend-mode: normal;
}
.fancybox-button {
  background-color: #0f284a;
  color: #fff;
}
.fancybox-container {
  z-index: 99999999999;
}
.fancybox-caption {
  background: none;
}
.fancybox-caption__body {
  width: 70%;
  margin: 0 auto;
}
/******************/
/*******************
    Layout Blocks
********************/
.block--two-col .row .col img {
  max-width: 100%;
  height: auto;
}
.block--two-thirds-with-background__content {
  padding: 3rem;
}
.block--two-thirds-with-background__bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.block--two-thirds-with-background__bg:after {
  content: '';
  display: block;
  padding-bottom: 40%;
}
.block--two-col-with-background__content {
  padding: 2rem;
}
.block--cta__content p {
  width: 100%;
  margin: 0 auto;
  font-size: 1.563em;
  margin-bottom: 2rem;
}
.block--cta__content p a.btn {
  font-size: 1rem;
}
.block--contact-section ul.contact-information {
  margin-bottom: 1.25rem;
}
.block--contact-section ul.contact-information li {
  margin-bottom: 1.25rem;
}
.block--contact-section ul.contact-information li a {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.25s ease-in-out;
}
.block--contact-section ul.contact-information li a:hover {
  opacity: 0.5;
}
.block--slider {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}
.block--slider:after {
  content: '';
  display: block;
  padding-bottom: 50%;
}
.block--slider__content {
  position: absolute;
  width: 80%;
  height: auto;
  margin: 0 auto;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
}
.block--slider__content .h1 {
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 125%;
}
.block--slider__content .h3 {
  font-size: 0.5rem;
  margin: 15px 0;
}
.block--page-header {
  margin-top: 104px;
}
.block--page-header__logo {
  width: 250px;
  height: auto;
}
.block--page-header__caption {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 3px;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #75D1E1;
  letter-spacing: 0.15rem;
  width: 100%;
  text-align: center;
}
.block--our-services__service {
  margin-bottom: 1.25rem;
}
.block--our-services__icon {
  margin-bottom: 1rem;
}
.block--our-services__content {
  margin: 0 auto;
  width: 13.75rem;
  color: #75D1E1;
}
.block--our-services__content p {
  font-weight: bolder;
  text-align: center !important;
  line-height: 1.5rem;
}
.block-accordion {
  border-bottom: 1px solid #D9D9D9;
}
.block-accordion.open .block-accordion__header .block-accordion__icon span.icon:after {
  font-family: 'fontello';
  content: '\f106';
}
.block-accordion__header {
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 1.25rem;
  background-color: #fff;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.7rem;
  margin: 0 0 25px 0;
  text-align: justify;
  font-size: 0.85rem;
  color: #0f284a;
  position: relative;
  margin-bottom: 0;
}
.block-accordion__header .block-accordion__heading {
  display: block;
  margin-right: 20px;
}
.block-accordion__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 60px;
  font-size: 1.25rem;
}
.block-accordion__icon span.icon:after {
  display: flex;
  font-family: 'fontello';
  content: '\f107';
}
.block-accordion__content {
  padding: 1.25rem;
  display: none;
}
.block--gallery__wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.block--gallery__item {
  display: inline-flex;
  margin: 0.75rem;
  width: 100%;
  height: auto;
}
.block--gallery__item img {
  height: 100%;
  width: 100%;
}
.block--gallery__item:focus {
  outline: 0;
}
/******************/
/***********************************/
/************************************
            General Styles
************************************/
/* CSS Document */
/*******************
    General Styles
********************/
/*******************
      Nav Styles
********************/
body.menu-open .nav-wrapper nav.main-navigation .container span.hamburger:before {
  transform: rotate(45deg) translateY(-50%);
  top: 50%;
}
body.menu-open .nav-wrapper nav.main-navigation .container span.hamburger:after {
  transform: rotate(-45deg) translateY(-50%);
  top: 50%;
}
body.menu-open .nav-wrapper nav.main-navigation .container span.hamburger > span {
  height: 0;
}
body.menu-open .nav-wrapper nav.main-navigation ul.menu-links {
  transform: translateY(0%);
}
.nav-wrapper {
  position: fixed;
  z-index: 999999;
  width: 100%;
}
.nav-wrapper nav.cta {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: #fff;
  position: relative;
  z-index: 99999;
}
.nav-wrapper nav.cta span {
  display: block;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 5px;
  color: #75D1E1;
  letter-spacing: 0;
  margin-bottom: 0;
}
.nav-wrapper nav.cta span a {
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  color: #75D1E1;
}
.nav-wrapper nav.cta span a:hover {
  opacity: 0.5;
}
.nav-wrapper nav.main-navigation {
  display: flex;
  background-color: #0f284a;
  position: relative;
}
.nav-wrapper nav.main-navigation .container {
  height: 60px;
  width: 100%;
  background-color: #0f284a;
  z-index: 99999;
}
.nav-wrapper nav.main-navigation .container a.menu-trigger {
  width: 44px;
  margin-left: 1rem;
}
.nav-wrapper nav.main-navigation .container a.menu-trigger .hamburger {
  margin: 0 auto;
  position: relative;
  width: 30px;
  height: 20px;
}
.nav-wrapper nav.main-navigation .container a.menu-trigger .hamburger:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #fff;
  transition-duration: 0.1s, 0.1s;
  transition-delay: 0.1s, 0s;
  transition-property: top,transform;
}
.nav-wrapper nav.main-navigation .container a.menu-trigger .hamburger:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transition-duration: 0.1s, 0.1s;
  transition-delay: 0.1s, 0s;
  transition-property: bottom,transform;
}
.nav-wrapper nav.main-navigation .container a.menu-trigger .hamburger > span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transform: translateY(-50%);
  transition: all 0.25s ease-in-out;
}
.nav-wrapper nav.main-navigation .container ul.social-icons {
  display: inline-flex;
  flex-direction: row;
  margin-right: 1rem;
  position: relative;
  z-index: 99999;
  background-color: #0f284a;
  margin-left: auto;
}
.nav-wrapper nav.main-navigation .container ul.social-icons li {
  margin: 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-wrapper nav.main-navigation .container ul.social-icons li a {
  font-size: 1.25rem;
  color: #fff;
  transition: all 0.25s ease-in-out;
}
.nav-wrapper nav.main-navigation .container ul.social-icons li a:hover {
  color: #75D1E1;
}
.nav-wrapper nav.main-navigation ul.menu-links {
  display: inline-flex;
  margin-right: auto;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #0f284a;
  flex-direction: column;
  width: 100%;
  transition: all 0.25s ease-in-out;
  transform: translateY(-200%);
}
.nav-wrapper nav.main-navigation ul.menu-links li {
  line-height: 300%;
  position: relative;
}
.nav-wrapper nav.main-navigation ul.menu-links li.current-menu-item.anchor-link a {
  color: #fff;
}
.nav-wrapper nav.main-navigation ul.menu-links li.current-menu-item a {
  color: #75D1E1;
}
.nav-wrapper nav.main-navigation ul.menu-links li a {
  color: #fff;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.85rem;
  transition: all 0.25s ease-in-out;
  margin: 0 1.5rem;
}
.nav-wrapper nav.main-navigation ul.menu-links li a:hover {
  color: #75D1E1;
}
.nav-wrapper nav.main-navigation ul.menu-links li > ul.submenu-links {
  display: block;
  width: 100%;
  background-color: #75D1E1;
  transition: all 0.25s ease;
}
.nav-wrapper nav.main-navigation ul.menu-links li > ul.submenu-links li {
  padding: 0 1rem;
  line-height: 300%;
}
.nav-wrapper nav.main-navigation ul.menu-links li > ul.submenu-links li a {
  transition: all 0.25s ease-in-out;
  margin: 0 1rem;
  color: #fff;
}
.nav-wrapper nav.main-navigation ul.menu-links li > ul.submenu-links li a:hover {
  opacity: 0.5;
}
/*******************/
/*******************
    Header Styles
********************/
/*******************/
/*******************
     Main Styles
********************/
body.home main.content {
  margin-top: 0;
}
main.content {
  margin-top: 104px;
}
/*******************/
/*******************
    Footer Styles
********************/
footer p.footer__copyright {
  margin-bottom: 0;
}
/*******************/
/***********************************/
/************************************
            Page Styles
************************************/
/* CSS Document */
/*******************
    Pages Styles
********************/
/***********************************/
/************************************
            Form Styles
************************************/
/* CSS Document */
/*******************
    Form Styles
********************/
.wpcf7 {
  display: inline-flex;
  width: 100%;
  margin: 0 0 0 0 !important;
}
.wpcf7 form {
  width: 100%;
  display: block;
}
.wpcf7 .ajax-loader {
  display: none !important;
}
.wpcf7 .wpcf7-form {
  margin: 0 auto;
}
.wpcf7 .wpcf7-form input[type=text],
.wpcf7 .wpcf7-form input[type=email],
.wpcf7 .wpcf7-form textarea {
  font-size: 1rem;
}
.wpcf7 .wpcf7-form input[type=text]::placeholder,
.wpcf7 .wpcf7-form input[type=email]::placeholder,
.wpcf7 .wpcf7-form textarea::placeholder {
  color: #fff;
}
.wpcf7 .wpcf7-form input[type=text] {
  width: calc(100% - .5em);
  padding: 0.5em;
  margin: 0.25em;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  font-size: 1.15em;
  border: 1px solid #fff;
  color: #fff;
}
.wpcf7 .wpcf7-form input[type=text].wpcf7-not-valid {
  border: 1px solid red;
}
.wpcf7 .wpcf7-form input[type=text]:focus {
  outline: 0;
  border: 1px solid #75D1E1;
}
.wpcf7 .wpcf7-form input[type=email] {
  width: calc(100% - .5em);
  padding: 0.5em;
  margin: 0.25em;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  font-size: 1.15em;
  border: 1px solid #fff;
  color: #fff;
}
.wpcf7 .wpcf7-form input[type=email].wpcf7-not-valid {
  border: 1px solid red;
}
.wpcf7 .wpcf7-form input[type=email]:focus {
  outline: 0;
  border: 1px solid #75D1E1;
}
.wpcf7 .wpcf7-form textarea {
  width: calc(100% - .5em);
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  font-size: 1.15em;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0.25em;
  border: 1px solid #fff;
  max-height: calc(178px - 2rem);
}
.wpcf7 .wpcf7-form textarea.wpcf7-not-valid {
  border: 1px solid red;
}
.wpcf7 .wpcf7-form textarea:focus {
  outline: 0;
  border: 1px solid #75D1E1;
}
.wpcf7 .wpcf7-form .wpcf7-submit {
  margin-top: 2em;
  margin-left: 2em;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 0.75em 1.5em;
  margin: 1.5rem 0;
  border-radius: 1.5em;
  text-decoration: none;
  display: inline-flex;
  line-height: 1em;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
  display: block;
  border: 2px solid #fff;
  background-color: #fff;
  color: #0f284a;
  width: 150px;
  text-align: center;
  font-size: 1.15rem;
  transition: all 0.25s ease-in-out;
}
.wpcf7 .wpcf7-form .wpcf7-submit:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}
.wpcf7 .wpcf7-form .wpcf7-not-valid-tip {
  display: none;
}
.wpcf7 .wpcf7-form .wpcf7-validation-errors {
  border: 0;
  text-align: center;
  font-weight: 700;
  font-style: italic;
  line-height: 100%;
  margin: 0 auto;
}
.wpcf7 .wpcf7-form .wpcf7-mail-sent-ok {
  border: 0;
  text-align: center;
  font-weight: 700;
  font-style: italic;
  line-height: 150%;
}
/***********************************/
/************************************
         Responsive Styles
************************************/
/* CSS Document */
/*********************
    min-width 370
**********************/
/*********************
    min-width 480
**********************/
/*********************
    min-width 767
**********************/
@media screen and (min-width: 767px) {
  .block--gallery__item {
    height: 250px;
    width: 250px;
  }
  .block--gallery__item img {
    height: 250px;
    width: 250px;
  }
  .block--gallery__item:focus {
    outline: 0;
  }
}
/*********************
    min-width 992
**********************/
@media screen and (min-width: 992px) {
  /*******************
        Layout Styles
    ********************/
  .wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .wrapper--small {
    width: 65%;
  }
  .row {
    flex-direction: row;
  }
  .row--reverse {
    flex-direction: row;
  }
  .row .col {
    display: inline-flex;
    flex-direction: column;
  }
  .row .col--gutter {
    padding: 1.5rem;
  }
  .row .col--gutter-large {
    padding: 3rem;
  }
  .row .col--1-1 {
    width: 100%;
  }
  .row .col--1-2 {
    width: 50%;
  }
  .row .col--1-3 {
    width: 33.33%;
  }
  .row .col--2-3 {
    width: 66.66%;
  }
  .row .col--1-4 {
    width: 25%;
  }
  .row .col--1-6 {
    width: 16.66%;
  }
  /*******************/
  /*******************
        Layout Blocks
    ********************/
  .block-accordion__header {
    font-size: 1.5rem;
  }
  .block--gallery__item {
    height: 400px;
    width: 400px;
    margin: 1rem;
  }
  .block--gallery__item img {
    height: 400px;
    width: 400px;
  }
  .block--gallery__item:focus {
    outline: 0;
  }
  .block--slider__content {
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .block--slider__content .h1 {
    font-size: 2.5rem;
  }
  .block--slider__content .h3 {
    font-size: 1.5rem;
  }
  .block--slider:after {
    content: '';
    display: block;
    padding-bottom: 47.91666%;
  }
  /*******************/
}
/*********************
    min-width 1200
**********************/
@media screen and (min-width: 1200px) {
  .parallax-window:after {
    content: '';
    padding-bottom: 100%;
  }
  /*******************
         Nav Styles
    ********************/
  .nav-wrapper nav.main-navigation {
    padding: 0 10%;
    background-color: #0f284a;
    height: 60px;
  }
  .nav-wrapper nav.main-navigation .container {
    background-color: rgba(0, 0, 0, 0);
    height: auto;
    z-index: auto;
  }
  .nav-wrapper nav.main-navigation .container a.menu-trigger {
    display: none;
  }
  .nav-wrapper nav.main-navigation ul.menu-links {
    flex-direction: row;
    transform: translateY(0%);
    top: 0;
    left: 10%;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.1s ease;
    height: 100%;
  }
  .nav-wrapper nav.main-navigation ul.menu-links li a {
    margin: 0 0.75rem;
  }
  .nav-wrapper nav.main-navigation ul.menu-links li:hover > ul.submenu-links {
    opacity: 1;
    visibility: visible;
  }
  .nav-wrapper nav.main-navigation ul.menu-links li > ul.submenu-links {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 240px;
  }
  /*******************/
  /*******************
       Slick Carousel
    ********************/
  .slider-controls {
    display: block;
  }
  .testimonial-slider .testimonial p {
    width: 80%;
    font-size: 2rem;
  }
  /*******************/
  /*******************
        Layout Blocks
    ********************/
  .block--two-col-with-background__content {
    padding: 5rem;
  }
  .block--cta__content p {
    width: 50%;
  }
  .block--slider:after {
    padding-bottom: 47.91666%;
  }
  .block--slider__content {
    position: absolute;
    width: 40%;
  }
  .block--page-header__logo {
    width: 470px;
    height: 240px;
  }
  /*******************/
}
/*********************
    min-width 1400
**********************/
@media screen and (min-width: 1440px) {
  /*******************
        Layout Blocks
    ********************/
  .block--two-col-with-background__content {
    padding: 5rem 10rem;
  }
  /*******************/
}
/*********************
    min-width 1600
**********************/
@media screen and (min-width: 1600px) {
  /*******************
        Layout Styles
    ********************/
  .wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .wrapper--small {
    width: 50%;
  }
  /*******************/
}
/*********************
    min-width 2560
**********************/
/***********************************/
