@charset "UTF-8";
/* CSS Document */
/*******************
       Mixins
********************/
/******************
    Fixes/Resets
*******************/
.clean-list-style {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
        background: none;
        line-height: 120%;
    }
}

.clean-list() {
    &:extend(.clean-list-style all);
}

.smooth-style {
    -webkit-font-smoothing: antialiased;
}

.smooth() {
    &:extend(.smooth-style);
}

/***************
     Forms
****************/
.placeholder(@arguments) {
    &::-webkit-input-placeholder {
        @arguments();
    }
    &:-moz-placeholder {
        @arguments();
    }
    &::-moz-placeholder {
        @arguments();
    }
    &:-ms-input-placeholder {
        @arguments();
    }
}

.form-field-setup() {
    width: ~'calc(100% - .5em)';
    padding: .5em;
    margin: .25em;
    background-color: @transparent-color;
    border-radius: 0;
    font-size: 1.15em;
    border: 1px solid @white;
    color: @white;
}
/****************
    Fonts
*****************/
.font-mixin(@font-family-fallbacks,@weight,@style) {
    font-family: @font-family-fallbacks;
    font-weight: @weight;
    font-style: @style;
}

/******************
      Button
*****************/
.btn-setup() {
    padding: .75em 1.5em;
    margin: 1.5rem 0;
    border-radius: 1.5em;
    text-decoration: none;
    display: inline-flex;
    line-height: 1em;
    text-align: center;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
}

/******************
      Loops
*****************/
// For //
//.for(@i, @n) {
//    .-each(@i)
//}
//
//.for(@n) when (isnumber(@n)) {
//    .for(1, @n)
//}
//
//.for(@i, @n) when not (@i = @n) {
//    .for((@i + (@n - @i) / abs(@n - @i)), @n);
//}

// For Each //
//.for(@array) when (default()) {
//    .for-impl_(length(@array))
//}
//
//.for-impl_(@i) when (@i > 1) {
//    .for-impl_((@i - 1))
//}
//
//.for-impl_(@i) when (@i > 0) {
//    .-each(extract(@array, @i))
//}
