@charset "UTF-8";
/* CSS Document */
html {
    box-sizing: border-box;
    overflow-x: hidden;
    font-size: 16px;
    scroll-behavior: smooth;
}
body {
    overflow-x: hidden;
}
#wpadminbar {
    z-index: 999999999 !important;
}
*, *:before, *:after {
    box-sizing: inherit;
}
.wrapper {
    width: 80%;
    margin: 0 auto;
    &--small {
        width: 80%;
    }
}
::selection {
    background-color: @main-color;
    color: @secondary-color;
}
img {
  &.aligncenter {
    display: block;
    margin: 0 auto;
  }
  &.size-full {
      padding: .5rem;
  }
}
/*******************/

.fancy-divider {
    height: 3px;
    margin: 1rem 0 2rem 0;

    &--small {
        width: 40px;
    }
    &--large {
        width: 70px;
    }
    &--main-color {
        background-color: @main-color;
    }
    &--secondary-color {
        background-color: @secondary-color;
    }
    &--left {
        margin-left: 0;
        margin-right: auto;
    }
    &--center {
        margin-left: auto;
        margin-right: auto;
    }
}

/*******************
   Section Styles
********************/
.section-white {
    background-color: @white;
}
.section-black {
    background-color: @black;
}
.section-main-color {
    background-color: @main-color;
    color: @white;
}
.section-secondary-color {
    background-color: @secondary-color;
}
/*******************/

/*******************
   Colour Styles
********************/
.white {
    color: @white;
}
.main-color {
    color: @main-color;
}
.secondary-color {
    color: @secondary-color;
}
/*******************/

/*******************
     Btn Styles
********************/
.btn {
    .btn-setup();
    &--primary {
        background-color: @main-color;
        color: @white;
        border: 2px solid @main-color;
        transition: .2s ease-in-out;
        &:hover {
            border: 2px solid @main-color;
            background-color: @transparent-color;
            color: @main-color;
        }
    }
    &--primary-outline {
        background-color: @transparent-color;
        color: @main-color;
        border: 2px solid @main-color;
        transition: .2s ease-in-out;
        &:hover {
            background-color: @main-color;
            color: @white;
        }
    }
    &--secondary {
        background-color: @secondary-color;
        color: @white;
        border: 2px solid @secondary-color;
        transition: .2s ease-in-out;
        &:hover {
            border: 2px solid @secondary-color;
            background-color: @transparent-color;
            color: @secondary-color;
        }
    }
    &--secondary-outline {
        background-color: @transparent-color;
        color: @secondary-color;
        border: 2px solid @secondary-color;
        transition: .2s ease-in-out;
        &:hover {
            background-color: @secondary-color;
            color: @white;
        }
    }
    &--white {
        background-color: @white;
        color: @main-color;
        border: 2px solid @white;
        transition: .2s ease-in-out;
        &:hover {
            border: 2px solid @white;
            background-color: @transparent-color;
            color: @white;
        }
    }
    &--small-btn {
        display: inline-block;
        width: 200px;
    }
    &--center {
        margin: 0 auto;
    }
}
/*******************/

/*******************
    Block Styles
********************/
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
/*******************/

/*******************
  Flex Box Styles
********************/
.flex {
    display: flex;

    &.flex--row {
        flex-direction: row;
    }

    &.flex--column {
        flex-direction: column;
    }

    &.flex--wrap {
        flex-wrap: wrap;
    }

    &.flex--justify-center {
        justify-content: center;
    }

    &.flex--justify-space-between {
        justify-content: space-between;
    }

    &.flex--justify-start {
        justify-content: flex-start;
    }

    &.flex--justify-end {
        justify-content: flex-end;
    }

    &.flex--align-stretch {
        align-items: stretch;
    }

    &.flex--align-self {
        align-self: center;
    }

    &.flex--align-center {
        align-items: center;
    }

    &.flex--align-start {
        align-items: flex-start;
    }

    &.flex--align-end {
        align-items: flex-end;
    }
}
/******************/

/*******************
       Layout
********************/
.row-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.row {
    display: flex;
    flex-direction: column;
    &--reverse {
        flex-direction: column-reverse;
    }
    .col {
        display: inline-flex;
        flex-direction: column;
        &--gutter {
            padding: 1.5rem 0;

            &-large {
                padding: 3rem 0;
            }
        }
        &--1-1 {
            width: 100%;
        }
        &--1-2 {
            width: 100%;
        }
        &--1-3 {
            width: 100%;
        }
        &--2-3 {
            width: 100%;
        }
        &--1-4 {
            width: 100%;
        }
        &--1-6 {
            width: 100%;
        }
    }

}
.padded {
    padding: 3rem 0;
    &--large {
        padding: 6rem 0;
    }
    &--top {
        padding-top: 3rem;

        &-large {
            padding-top: 6rem;
        }
    }
    &--bottom {
        padding-bottom: 3rem;

        &-large {
            padding-bottom: 6rem;
        }
    }
    &--no-top {
        padding-top: 0;
    }
    &--no-bottom {
        padding-bottom: 0;
    }
}
/*******************/

/*******************
    Slick Carousel
********************/
/* Defaults */
.slider-container {
    position: relative;
}
.slide {
    outline: 0;
}
.slick-dots {
    display: flex;
    justify-content: center;
    margin: 1em 0;
    .clean-list();
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -10%);
    li {
        margin: 0 .5em;
        button {
            font-size: 0;
            height: 10px;
            width: 10px;
            padding: 0;
            border-radius: 5px;
            background-color: rgba(255,255,255,.35);
            border: 0;
            outline: none;

            &:hover {
                opacity: .8;
                cursor: pointer;
            }
        }

        &.slick-active {
            button {
                background-color: @white;
                border: 0;
            }
        }
    }
}
.slider-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    display: none; //block
    font-size: 3rem;
    color: @white;

    .slick-prev {
        left: 0;
        position: absolute;
        transition: all .25s ease-in-out;

        &:hover {
            opacity: .5;
            cursor: pointer;
        }
    }
    .slick-next {
        right: 0;
        position: absolute;
        transition: all .25s ease-in-out;

        &:hover {
            opacity: .5;
            cursor: pointer;
        }
    }
}

.testimonial-slider {
    .slick-dots {
        bottom: auto;
        transform: translate(-50%, 0);
        li {
            button {
                background-color: fade(@main-color, 40%);
            }
            &.slick-active {
                button {
                    background-color: @main-color;

                }
            }
        }
    }
    .slider-controls {
        color: @secondary-color;
    }
    .testimonial {
        p {
            width: 100%; //80%;
            margin: 0 auto;
            font-size: 1.5rem; //2rem;
            margin-bottom: 2rem;
        }
        &__business-name {
            color: @secondary-color;
        }
    }
}
.logo-slider {
    .slick-dots {
        bottom: auto;
        transform: translate(-50%, 0);
        li {
            button {
                background-color: fade(@main-color, 40%);
            }
            &.slick-active {
                button {
                    background-color: @main-color;

                }
            }
        }
    }
    .slider-controls {
        color: @secondary-color;
    }
    .slide a, .slide img {
        &:focus {
            outline: 0;
        }
    }
    .slide a img, .slide img {
        margin: 0 auto;
        &:focus {
            outline: 0;
        }
    }
}
/*******************/

/*******************
 Animate On Scroll
********************/
[data-aos] {
    visibility: hidden;
}
[data-aos].aos-animate {
    visibility: visible;
}
/******************/

/*******************
Invisible reCaptcha
********************/
.inv-recaptcha-holder {
    padding: 25px 0;
}
/******************/

/*******************
  Parallax Styles
********************/
.parallax-window {
    background: @transparent-color;
    min-height: 100%;
    transition: all 0.3s ease;
    &:after {
        content: '';
        padding-bottom: 50%; //100%;
    }
}
/******************/

/*******************
  Fancy Box Styles
********************/
.compensate-for-scrollbar {
    margin-right: 0 !important;
}
.fancybox {
    &-bg {
        background-color: @main-color;
    }
    &-infobar {
        color: @white;
        mix-blend-mode: normal;
    }
    &-button {
        background-color: @main-color;
        color: @white;
    }
    &-container {
        z-index: 99999999999;
    }
    &-caption {
        background: none;
        &__body {
            width: 70%;
            margin: 0 auto;
        }
    }
}
/******************/

/*******************
    Layout Blocks
********************/
.block--two-col {
  .row {
    .col {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
.block--two-thirds-cols {}
.block--two-thirds-with-background {
    &__content {
        padding: 3rem;
    }
    &__bg {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:after {
            content: '';
            display: block;
            padding-bottom: 40%;
        }
    }
}
.block--full-width {
    &__content {}
}
.block--two-col-with-background {
    &__content {
        padding: 2rem; //10rem;
    }
}
.block--cta {
    &__content {
        p {
            width: 100%; //50%
            margin: 0 auto;
            font-size: 1.563em;
            margin-bottom: 2rem;

            a {
                &.btn {
                    font-size: 1rem;
                }
            }
        }
    }
}
.block--contact-section {
    ul.contact-information {
        .clean-list();
        margin-bottom: 1.25rem;

        li {
            margin-bottom: 1.25rem;
            a {
                color: @white;
                text-decoration: none;
                font-weight: 700;
                transition: all .25s ease-in-out;
                &:hover {
                    opacity: .5;
                }
            }
        }
    }
}
.block--slider {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    &:after {
        content: '';
        display: block;
        padding-bottom: 50%;//47.91666%;
    }
    &__content {
      //display: none;
        position: absolute;
        width: 80%;//40%;
        height: auto;
        margin: 0 auto;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -30%);
        text-align: center;

        .h1 {
          font-size: 1rem;
          margin-bottom: 0;
            line-height: 125%;
        }
        .h3 {
          font-size: .5rem;
            margin: 15px 0;
        }
    }
}
.block--page-header {
    margin-top: 104px; // navigation offset

    &__logo {
        width: 250px; //470px;
        height: auto; //240px;
    }
    &__caption {
        .defaultHeadingFont();
        font-size: 1.25rem;
        text-transform: uppercase;
        color: @secondary-color;
        letter-spacing: 0.15rem;
        width: 100%;
        text-align: center;
    }
}
.block--our-services {
    &__service {
        margin-bottom: 1.25rem;
    }
    &__icon {
        margin-bottom: 1rem;
    }
    &__content {
        margin: 0 auto;
        width: 13.750rem;
        color: @secondary-color;
        p {
            font-weight: bolder;
            text-align: center !important;
            line-height: 1.5rem;
        }
    }
}
.block-accordion {
    border-bottom: 1px solid @grey;
    &.open {
        .block-accordion__header {
            .block-accordion__icon {
                span.icon {
                    &:after {
                        font-family: 'fontello';
                        content: '\f106';
                    }
                }
            }
        }
    }
    &__header {
        display: block;
        width: 100%;
        text-decoration: none;
        padding: 1.25rem;
        background-color: @white;
        .defaultP();
        font-size: .85rem; //1.5rem;
        color: @main-color;
        position: relative;
        margin-bottom: 0;

        .block-accordion__heading {
            display: block;
            margin-right: 20px;
        }
    }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 60px;
        font-size: 1.25rem;
        span.icon {
            &:after {
                display: flex;
                font-family: 'fontello';
                content: '\f107';
            }
        }
    }
    &__content {
        padding: 1.25rem;
        display: none;
    }
}
.block--gallery {
    &__wrapper {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    &__item {
        display: inline-flex;
        margin: .75rem;
        width: 100%;
        height: auto;
        img {
            height: 100%;
            width: 100%;
        }
        &:focus {
            outline: 0;
        }
    }
}
/******************/

