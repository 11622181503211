@charset "UTF-8";
/* CSS Document */

/*********************
    min-width 370
**********************/
@media screen and (min-width: 370px) {}

/*********************
    min-width 480
**********************/
@media screen and (min-width: 480px) {}

/*********************
    min-width 767
**********************/
@media screen and (min-width: 767px) {
    .block--gallery {
        &__item {
            height: 250px;
            width: 250px;
            img {
                height: 250px;
                width: 250px;
            }
            &:focus {
                outline: 0;
            }
        }
    }
}

/*********************
    min-width 992
**********************/
@media screen and (min-width: 992px) {
    /*******************
        Layout Styles
    ********************/
    .wrapper {
        width: 80%;
        margin: 0 auto;
        &--small {
            width: 65%;
        }
    }
    .row {
        flex-direction: row;
        &--reverse {
            flex-direction: row;
        }
        .col {
            display: inline-flex;
            flex-direction: column;
            &--gutter {
                padding: 1.5rem;

                &-large {
                    padding: 3rem;
                }
            }
            &--1-1 {
                width: 100%;
            }
            &--1-2 {
                width: 50%;
            }
            &--1-3 {
                width: 33.33%;
            }
            &--2-3 {
                width: 66.66%;
            }
            &--1-4 {
                width: 25%;
            }
            &--1-6 {
                width: 16.66%;
            }
        }
    }
    /*******************/

    /*******************
        Layout Blocks
    ********************/
    .block-accordion {
        &__header {
            font-size: 1.5rem;
        }
    }
    .block--gallery {
        &__item {
            height: 400px;
            width: 400px;
            margin: 1rem;
            img {
                height: 400px;
                width: 400px;
            }
            &:focus {
                outline: 0;
            }
        }
    }
    .block--slider {
        &__content {
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .h1 {
                font-size: 2.5rem;
            }
            .h3 {
                font-size: 1.5rem;
            }
        }
        &:after {
            content: '';
            display: block;
            padding-bottom: 47.91666%;
        }
    }
    /*******************/
}

/*********************
    min-width 1200
**********************/
@media screen and (min-width: 1200px) {
    .defaultList() {
        margin-left: 5rem;
    }

    .parallax-window {
        &:after {
            content: '';
            padding-bottom: 100%;
        }
    }

    /*******************
         Nav Styles
    ********************/
    .nav-wrapper {
        nav {
            &.cta {}
            &.main-navigation {
                padding: 0 10%;
                background-color: @main-color;
                height: 60px;
                .container {
                    background-color: @transparent-color;
                    height: auto;
                    z-index: auto;
                    a.menu-trigger {
                        display: none;
                    }
                }
                ul.menu-links {
                    flex-direction: row;
                    transform: translateY(0%);
                    top: 0;
                    left: 10%;
                    background-color: @transparent-color;
                    transition: all 0.1s ease;
                    height: 100%;
                    li {
                        a {
                            margin: 0 .75rem;
                        }
                        &:hover {
                            > ul.submenu-links {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                        > ul.submenu-links {
                            position: absolute;
                            opacity: 0;
                            visibility: hidden;
                            width: 240px;
                        }
                    }
                }
            }
        }
    }
    /*******************/

    /*******************
       Slick Carousel
    ********************/
    .slider-controls {
        display: block;
    }
    .testimonial-slider {
        .testimonial {
            p {
                width: 80%;
                font-size: 2rem;
            }
        }
    }
    /*******************/

    /*******************
        Layout Blocks
    ********************/
    .block--two-col-with-background {
        &__content {
            padding: 5rem;
        }
    }
    .block--cta {
        &__content {
            p {
                width: 50%;
            }
        }
    }
    .block--slider {
        &:after {
            padding-bottom: 47.91666%;
        }
        &__content {
            position: absolute;
            width: 40%;
        }
    }
    .block--page-header {
        &__logo {
            width: 470px;
            height: 240px;
        }
    }
    /*******************/
}

/*********************
    min-width 1400
**********************/
@media screen and (min-width: 1440px) {
    /*******************
        Layout Blocks
    ********************/
    .block--two-col-with-background {
        &__content {
            padding: 5rem 10rem;
        }
    }
    /*******************/
}

/*********************
    min-width 1600
**********************/
@media screen and (min-width: 1600px) {
    /*******************
        Layout Styles
    ********************/
    .wrapper {
        width: 80%;
        margin: 0 auto;
        &--small {
            width: 50%;

        }
    }
    /*******************/
}

/*********************
    min-width 2560
**********************/
@media screen and (min-width: 2560px) {}