@charset "UTF-8";
/* CSS Document */
/*******************
    Font Styles
********************/
html, body {
    .smooth();
    font-family: sans-serif;
}

.defaultHeadingFont() {
    .smooth();
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.defaultBodyFont() {
    .smooth();
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.defaultFont() {
    .defaultBodyFont();
}
/******************/

/*******************
  Text Align Styles
********************/
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
/*******************/

/****************
    Text Styles
*****************/
.defaultH1() {
    .defaultHeadingFont();
    font-size: 2.5rem;
    line-height: 150%;
    margin: 0 0 15px;
    font-weight: 900;
}

.defaultH2() {
    .defaultHeadingFont();
    font-size: 2.25rem;
    line-height: 125%;
    margin: 0 0 15px;
}

.defaultH3() {
    .defaultHeadingFont();
    font-size: 1.25rem;
    line-height: 125%;
    margin: 0 0 15px;
}

.defaultH4() {
    .defaultHeadingFont();
    margin-bottom: 5px;
}

.defaultH5() {
    .defaultHeadingFont();
    margin-bottom: 5px;
}

.defaultH6() {
    .defaultHeadingFont();
    margin-bottom: 5px;
}

.defaultP() {
    .defaultBodyFont();
    font-size: 1rem;
    line-height: 1.7rem;
    margin: 0 0 25px 0;
    text-align: justify;
}

.defaultA() {
    text-decoration: none;
    transition: 0.1s;

    &:hover {
        color: @main-color;
        text-decoration: none;
    }
    &:active {
        color: @main-color;
    }
}

.defaultList() {
    margin-left: 2.5rem;
    margin-bottom: 1rem;
    list-style: disc;
    li {
        margin-bottom: .75rem;
        display: list-item;
        line-height: 1.5rem;
    }
}

h1, .h1 {
    .defaultH1();
}

h2, .h2 {
    .defaultH2();
}

h3, .h3 {
    .defaultH3();
}

h4, .h4 {
    .defaultH4();
}

h5, .h5 {
    .defaultH5();
}

h6, .h6 {
    .defaultH6();
}

p, .p {
    .defaultP();

    a {
        &:not(.btn) {
            text-decoration: none;
            font-weight: bold;
            color: inherit;
            transition: all .25s ease-in-out;
            &:hover {
                opacity: .5;
            }
        }
    }

    &.h1, .h2, .h3, .h4, .h5, .h6 {
        text-align: unset;
    }
}

ul, .ul {
    .defaultList();
}
/****************/