@charset "UTF-8";
/* CSS Document */
/*******************
    Form Styles
********************/
.wpcf7 {
    display: inline-flex;
    width: 100%;
    margin: 0 0 0 0 !important;

    form {
        width: 100%;
        display: block;
    }

    .ajax-loader {
        display: none !important;

    }

    .wpcf7-form {
        margin: 0 auto;
        input[type=text], input[type=email], textarea {
            font-size: 1rem;
            &::placeholder {
                color: @white;
            }
        }
        input[type=text] {
            .form-field-setup();
            &.wpcf7-not-valid {
                border: 1px solid red;
            }
            &:focus {
                outline: 0;
                border: 1px solid @secondary-color;
            }
        }
        input[type=email] {
            .form-field-setup();
            &.wpcf7-not-valid {
                border: 1px solid red;
            }
            &:focus {
                outline: 0;
                border: 1px solid @secondary-color;
            }
        }
        textarea {
            .form-field-setup();
            .defaultFont();
            margin: .25em;
            border: 1px solid @white;
            max-height: ~'calc(178px - 2rem)';
            &.wpcf7-not-valid {
                border: 1px solid red;
            }
            &:focus {
                outline: 0;
                border: 1px solid @secondary-color;
            }
        }
        .wpcf7-submit {
            margin-top: 2em;
            margin-left: 2em;
            .defaultFont();
            outline: 0;
            .btn-setup();
            display: block;
            border: 2px solid @white;
            background-color: @white;
            color: @main-color;
            width: 150px;
            text-align: center;
            font-size: 1.15rem;
            transition: all .25s ease-in-out;
            &:hover {
                color: @white;
                background-color: @transparent-color;
            }
        }
        .wpcf7-not-valid-tip {
            display: none;
        }
        .wpcf7-validation-errors {
            border: 0;
            text-align: center;
            font-weight: 700;
            font-style: italic;
            line-height: 100%;
            margin: 0 auto;
        }
        .wpcf7-mail-sent-ok {
            border: 0;
            text-align: center;
            font-weight: 700;
            font-style: italic;
            line-height: 150%;
        }
    }
}